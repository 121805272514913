import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./saisons-logo.png" alt="L'application Saisons" />
        <p style={{fontSize: 22}}>
          L'application québécoise qui<br/>
          facilite le recrutement<br />
          d'employés saisonniers
        </p>
        <p style={{fontSize: 16}}>
          À venir bientôt!
        </p>
      </header>
    </div>
  );
}

export default App;
